<template>
  <b-container class="noProjects-content mb-5 p-0">
    <b-card class="illustration-card text-center mt-3">
      <b-row class="mt-5">
        <b-col class="p-0">
          <b-img
            src="/images/noProjectsIllustration.svg"
          />
        </b-col>
      </b-row>
      <b-row v-if="$route.name === 'BusinessProjects'">
        <b-col>
          <label class="h8 title ml-3 mt-5">
            No projects yet!
          </label>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col>
          <label class="h8 title ml-3 mt-5">
            No matches yet!
          </label>
        </b-col>
      </b-row>
      <b-row v-if="$route.name === 'BusinessProjects'">
        <b-col class="h9 pl-6 pr-6 mt-2">
          <p class="desc-text">
            Would you like to add your first project?
            Let us know and book a personal call with our success team who will happily assist you.
          </p>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col class="h9 pl-6 pr-6 mt-2 mb-4">
          <p class="desc-text">
            We're hand-picking the best matches from our Recruiter Base and from
            all around the web, if needed. Stay tuned, you will be notified by email.
          </p>
        </b-col>
      </b-row>
      <b-row
        v-if="$route.name === 'BusinessProjects'"
        class="mb-3"
      >
        <b-col>
          <b-btn
            class="ml-3 mt-4"
            variant="secondary"
            target="_plank"
            @click="openCalendly"
          >
            <i class="ri-vidicon-line pr-2" />
            <span>
              Book a call
            </span>
          </b-btn>
        </b-col>
      </b-row>
    </b-card>
  </b-container>
</template>

<script>
export default {
  name: 'NoProjects',
  methods: {
    openCalendly () {
      if (this.$store.state.auth.selectedBusiness.advisor === 'Diana Solonenkova') {
        window.open('https://calendly.com/diana-solonenkova/30min')
      } else {
        window.open('https://calendly.com/mononen-ina/30min')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.noProjects-content {
  max-width: 74vw;
  .illustration-card {
    background-color: #fff;
    box-shadow: 0px 4px 12px rgba(216, 216, 216, 0.5);
    border-radius: 12px;
    border: none;
    .title {
      font-size: 18px;
    }
    .desc-text {
      max-width: 350px;
      margin: auto;
    }
  }
}
</style>
