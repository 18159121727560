<template>
  <b-container class="content-matches mb-5 ml-3">
    <b-row
      class="mt-2 mb-2 ml-1"
      align-v="end"
      cols="2"
    >
      <!-- Status tabs -->
      <b-col
        class="d-inline-flex filters-row pr-0"
        style="border-bottom: 1px solid #e4e9f9"
      >
        <b-nav>
          <b-nav-item
            link-classes="py-2 py-sm-2 px-2 px-sm-3 filters"
            :class="{ active: statusFilter === 'open' }"
            :disabled="!counts.new"
            @click="setMatchFilter('open')"
          >
            New({{ counts.new }})
          </b-nav-item>
        </b-nav>
        <b-nav>
          <b-nav-item
            link-classes="py-2 py-sm-2 px-2 px-sm-3 filters"
            :disabled="!counts.selected"
            :class="{ active: statusFilter === 'selected' }"
            @click="setMatchFilter('selected')"
          >
            Selected({{ counts.selected }})
          </b-nav-item>
        </b-nav>
        <b-nav>
          <b-nav-item
            link-classes="py-2 py-sm-2 px-2 px-sm-3 filters"
            :disabled="!counts.hired"
            :class="{ active: statusFilter === 'hired' }"
            @click="setMatchFilter('hired')"
          >
            Hired({{ counts.hired }})
          </b-nav-item>
        </b-nav>
        <b-nav>
          <b-nav-item
            link-classes="py-2 py-sm-2 px-2 px-sm-3 filters"
            :class="{ active: statusFilter === 'all' }"
            @click="setMatchFilter('all')"
          >
            All({{ counts.all }})
          </b-nav-item>
        </b-nav>
      </b-col>
      <!-- Project filter -->
      <b-col class="filter-bar text-right pr-3">
        <b-form-group
          label-for="project-filter"
          label-class="mb-0"
          label-size="lg"
        >
          <b-form-select
            v-model="projectFilter"
            class="project-filter"
            variant="beige"
            :options="projectsFilterOptions"
          >
            <template #first>
              <b-form-select-option
                :value="null"
                disabled
              >
                Filter by project
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row
      v-for="match in filteredMatches()"
      :key="match.id"
    >
      <b-col>
        <MatchCard :match="match" />
      </b-col>
    </b-row>
    <b-row
      v-if="!counts.all"
      no-gutters
    >
      <b-col class="p-0">
        <NoProjects />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import MatchCard from '@/modules/business/components/MatchCard'
import NoProjects from '@/modules/business/components/NoProjects'
import { mapState, mapGetters } from 'vuex'
import axios from '@/helpers/cleanAxios'

export default {
  name: 'BusinessMatches',
  components: {
    MatchCard,
    NoProjects,
  },
  data () {
    return {
      dataLoaded: false,
      statusFilter: 'all',
      projectFilter: '',
      selectedProject: null,
      counts: {
        all: 0,
        selected: 0,
        hired: 0,
        new: 0,
      },
      coverLetter: '',
      recruiterFullName: '',
      matchToDelete: null,
    }
  },
  computed: {
    ...mapState('projects', {
      projects: state => state.projects,
    }),
    ...mapState('matches', {
      matches: state => state.matches,
    }),
    ...mapGetters('matches', [
      'selected',
      'hired',
      'new',
      'all',
    ]),
    projectsFilterOptions () {
      const values = [{ value: null, text: 'All projects' }]
      this.projects.forEach(project => {
        values.push({
          value: project.id,
          text: project.title,
        })
      })
      return values
    },
  },
  watch: {
    projectFilter () {
      this.setCounts()
    },
  },
  created () {
    this.projectFilter = this.$route.query.project || null
    Promise.all([
      this.$store.dispatch('projects/getProjects'),
      this.$store.dispatch('matches/getMatches'),
    ])
      .finally(() => {
        this.dataLoaded = true
        this.setCounts()
      })
  },
  methods: {
    filteredMatches () {
      if (this.statusFilter === 'all') {
        return this.all(this.projectFilter)
      }
      if (this.statusFilter === 'selected') {
        return this.selected(this.projectFilter)
      }
      if (this.statusFilter === 'hired') {
        return this.hired(this.projectFilter)
      }
      return this.new(this.projectFilter)
    },
    setCounts () {
      this.counts.all = this.all(this.projectFilter).length
      this.counts.selected = this.selected(this.projectFilter).length
      this.counts.hired = this.hired(this.projectFilter).length
      this.counts.new = this.new(this.projectFilter).length
    },

    setMatchFilter (filter) {
      this.statusFilter = filter
      this.setCounts()
    },

    showMoreMatches () {
      axios.patch(`/v2/projects/${this.projectFilter || this.selectedProject}/more-matches`, {
        feedback: this.matches.moreMatchesText,
      })
      this.$bvModal.msgBoxOk('Await for a new matches within 1-3 days. We will hand-pick the best recruiters who fit your needs.', {
        title: 'Thanks for your feedback!',
        size: 'md',
        buttonSize: 'sm',
        okVariant: 'danger',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true,
      })
    },
    showCoverLetterModal (data1, data2) {
      this.$root.$emit('bv::show::modal', 'modal-4', '#btnShowCoverLetter', data1, data2)
      this.coverLetter = data1
      this.recruiterFullName = data2
    },
    deleteMatchModal (match) {
      this.$root.$emit('bv::show::modal', 'modal-5', '#btnDeleteMatch')
      this.matchToDelete = match
    },
    deleteMatch () {
      axios.delete(`/admin/v2/matches/${this.matchToDelete}`)
        .then(() => {
          this.$store.dispatch('matches/getMatches')
        })
    },
    saved () {
      this.dataLoaded = false
      this.$store.dispatch('matches/getMatches')
        .then(() => {
          this.setCounts()
          this.dataLoaded = true
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.content-matches {
  max-width: 74vw;
  .filters-row {
    justify-content: space-between;
    .filters {
      color: #475270;
      font-weight: 500;
      font-size: 14px;
    }
    .active {
      border-bottom: 5px solid #ee8b7b;
    }
  }

  .filter-bar {
    top: 15px;
    .project-filter {
      max-width: 288px;
      font-weight: 400;
      font-size: 16px;
      color:#39364F;
    }
  }
}
</style>
